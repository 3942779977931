import { setRemoteDefinitions } from '@nx/angular/mf';

fetch('/assets/glass.manifest.json')
  .then((res) => res.json())
  .then((definitions) => {
    const remotes = definitions.remotes;
    const remoteDefinitions =
      remotes
        ?.map((remote: { __id: any; remoteUrl: any }) => {
          return {
            [remote.__id]: remote.remoteUrl,
          };
        })
        .reduce((result: any, item: any) => {
          return {
            ...result,
            ...item,
          };
        }, {}) ?? {};

    return setRemoteDefinitions(remoteDefinitions);
  })
  .then(() => import('./bootstrap').catch((err) => console.error(err)));
